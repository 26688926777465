<template>
  <div
    class="flex gap-1 px-6 py-2 border-t border-gray-200 relative"
    :class="{ 'justify-between': !center, 'justify-around': center }"
  >
    <div class="submenu" :class="{ active: $slots['sub-menu'] && showSubmenu }">
      <slot name="sub-menu"></slot>
    </div>
    <div
      class="arrow-container"
      :class="{ active: $slots['sub-menu'] && showSubmenu }"
      :style="{ left: submenuArrowPos + 'px' }"
    >
      <div class="arrow"></div>
    </div>
    <slot name="buttons"></slot>
  </div>
</template>
<script>
export default {
  props: {
    center: {
      type: Boolean,
      default: false,
    },
    submenuArrowPos: {
      type: Number,
      default: 50,
    },
    showSubmenu: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style scoped>
.submenu {
  @apply fixed z-20 transition-all duration-150 opacity-0 left-0 right-0 h-0 bg-gray-50 border-opacity-50 transform -translate-y-1 overflow-hidden;
  transition-delay: 150ms;
  border-top: 1px #ffffff solid;
  border-bottom: 1px rgb(221, 221, 221) solid;
  bottom: 3.4rem;
}
.submenu.active {
  @apply opacity-100 h-14;
  transition-delay: 0ms;
}
.arrow-container {
  @apply fixed bottom-12 transition-all overflow-hidden h-5 w-6 -ml-3 -translate-y-0.5 opacity-0 flex justify-center;
  transition-delay: 0ms;
  z-index: 21;
}
.arrow {
  @apply border-4 border-gray-300 bg-gray-50 h-3.5 w-3.5 transform rotate-45 -mt-1;
  border-width: 0 1px 1px 0;
}
.arrow-container.active {
  transition-delay: 150ms;
  @apply opacity-100 bottom-10;
}
</style>
